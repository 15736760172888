import AppConfigService from '@src/service/common/AppConfigService';
import { StaticPageRoutingHelper } from '@src/service/util/StaticPageRoutingHelper';
import evaluateBrowser from 'outdated-browser-rework/evaluateBrowser';
import UserAgentParser from 'ua-parser-js';

const browserSupport = AppConfigService.getValue('app.browserSupport');

const parsedUserAgent = new UserAgentParser(navigator.userAgent).getResult(); // same as used in 'outdated-browser-rework'

const browserEvaluation = evaluateBrowser(parsedUserAgent, { browserSupport, isUnknownBrowserOK: true });

if (browserEvaluation.isBrowserUnsupported) {
  StaticPageRoutingHelper.route('/unsupportedBrowser.html');
}
